'use client';

import Section from '@@components/Section';
import { clx_ } from '@@libs/clx';
import { motion } from 'framer-motion';
import React from 'react';
import Link from 'next/link';
// import reactStringReplace from 'react-string-replace';
import css from './Roadmap.module.scss';

const clx = clx_(css);

export default function Roadmap() {
  return (
    <Section id="roadmap" title="Roadmap" className="grid gap-4">
      {roadMap.map(({ text, title, images }, i) => (
        <motion.div
          key={i}
          className={clx('wrap')}
          whileInView="show"
          initial="hide"
          viewport={{ margin: '2000px 0px -200px 0px' }}
          variants={{
            hide: {
              opacity: 0,
              x: i % 2 == 0 ? -25 : 25,
            },
            show: {
              opacity: 1,
              x: 0,
            },
          }}
        >
          <span className={clx('badge')}>{title}</span>
          <span className={clx('item')}>
            {text}
            {/* {reactStringReplace(text, '{site}', (m, i) => (
              <Link href={listingSite.href} key={i} className="px-2 py-1 uppercase rounded bg-black/30" target="_blank" rel="noopener">
                {listingSite.name}
              </Link>
            ))} */}
            {!!images?.length && (
              <div className="mt-4 flex flex-col gap-4">{images?.map((img) => <img key={img} src={img} className="m-auto max-w-80 rounded-md" />)}</div>
            )}
          </span>
        </motion.div>
      ))}
    </Section>
  );
}

const roadMap = [
  {
    title: 'Title1',
    text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, nulla commodi, aliquid, et maxime veniam quidem accusantium dicta nesciunt dolores quia sit assumenda quas veritatis totam? Impedit earum rem rerum.`,
  },
  {
    title: 'Title2',
    text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, nulla commodi, aliquid, et maxime veniam quidem accusantium dicta nesciunt dolores quia sit assumenda quas veritatis totam? Impedit earum rem rerum.`,
  },
  {
    title: 'Title3',
    text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, nulla commodi, aliquid, et maxime veniam quidem accusantium dicta nesciunt dolores quia sit assumenda quas veritatis totam? Impedit earum rem rerum.`,
    images: ['/imgs/logo_255.png'],
  },
  {
    title: 'Title4',
    text: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, nulla commodi, aliquid, et maxime veniam quidem accusantium dicta nesciunt dolores quia sit assumenda quas veritatis totam? Impedit earum rem rerum.`,
  },
];
