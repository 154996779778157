'use client';

import Section, { BasicSectionProps } from '@@components/Section';
import { SocialLinks } from '@@configs/page/site.config';
import { clx_ } from '@@libs/clx';
import { motion } from 'framer-motion';
import Link from 'next/link';
import css from './Community.module.scss';

const clx = clx_(css);
type CommunityProps = BasicSectionProps & {
  //
};
export default function Community({ title, href }: CommunityProps) {
  return (
    <Section
      id={href}
      title={title}
      className=""
      initial="hide"
      variants={{
        hide: {},
        show: {
          transition: {
            duration: 0.5,
            staggerChildren: 0.5,
          },
        },
      }}
      whileInView="show"
      viewport={{ amount: 'some', margin: '2000px 0px -200px 0px' }}
    >
      <motion.span
        className="text-center text-2xl"
        variants={{
          hide: {
            opacity: 0,
            y: 40,
            // rotateZ: -180,
          },
          show: {
            opacity: 1,
            y: 0,
            // rotateZ: 0,
            // transition: { }
          },
        }}
      >
        Join our Telegram and TikTok, and others — news, airdrops, great deals and top community!
      </motion.span>

      <motion.div
        className="mt-12 flex flex-wrap justify-around gap-8"
        variants={{
          hide: {
            // opacity: 0,
            // y: 20,
            // rotateZ: -180,
          },
          show: {
            // opacity: 1,
            // y: 0,
            transition: {
              staggerChildren: 0.25,
            },
          },
        }}
      >
        {SocialLinks.map((v, i) => (
          <motion.a
            key={i}
            href={v.href}
            className={clx('link')}
            // component={Link as any}

            variants={{
              hide: {
                opacity: 0,
                y: 40,
                // rotateZ: -180,
              },
              show: {
                opacity: 1,
                y: 0,
              },
            }}
          >
            <img src={v.icon} alt={v.name} className={clx('')} />
          </motion.a>
        ))}
      </motion.div>
    </Section>
  );
}
