'use client';

import Section, { BasicSectionProps } from '@@components/Section';
import { clx_ } from '@@libs/clx';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import css from './Faq.module.scss';

const clx = clx_(css);

type FaqProps = BasicSectionProps & {
  //
};
export default function Faq({ title, href }: FaqProps) {
  const [status, setStatus] = useState(Object.fromEntries(faqData.map(({ id }) => [id, false])));

  return (
    <Section
      id={href}
      title={title}
      className="grid gap-3"
      initial="hide"
      // transition={{  }}
      variants={{
        hide: {},
        show: {
          transition: {
            duration: 0.5,
            staggerChildren: 0.1,
          },
        },
      }}
      whileInView="show"
      viewport={{ amount: 'some', margin: '2000px 0px -200px 0px' }}
      // onViewportEnter={(...args) => console.log(...args)}
    >
      {faqData.map(({ id, q, a }, i) => (
        <motion.article
          key={id}
          className={clx('item', { opened: status[id] })}
          onClick={() => setStatus((d_) => Object.fromEntries(Object.entries(d_).map(([k, v]) => [k, k == id ? !v : false])))}
          // whileInView="show"
          // initial="hide"
          // viewport={{ margin: '2000px 0px -100px 0px' }}
          transition={{ stiffness: 0 }}
          variants={{
            hide: {
              opacity: 0,
              y: 20,
              // rotateZ: -180,
            },
            show: {
              opacity: 1,
              y: 0,
              // rotateZ: 0,
            },
          }}
        >
          <motion.div>
            <motion.h1>{q}</motion.h1>
            <motion.button title="expand/close">
              <motion.span></motion.span>
              <motion.span></motion.span>
            </motion.button>
          </motion.div>
          <motion.p
            variants={{
              hide: {
                opacity: 0,
                height: 0,
                paddingBlock: '0rem',
                transition: { duration: 0.25, stiffness: 0 },
                transitionEnd: {
                  display: 'none',
                },
              },
              show: {
                opacity: 1,
                height: 'auto',
                paddingBlock: '0.75rem',
                display: 'block',
              },
            }}
            initial="hide"
            animate={status[id] ? 'show' : 'hide'}
            // layout
          >
            {a}
          </motion.p>
        </motion.article>
      ))}
    </Section>
  );
}

const faqData = [
  {
    id: 'nft',
    q: "What's an NFT?",
    a: "NFT stands for 'non-fungible token. An NFT is basically data that is accounted for in a digital ledger, and that data represents something specific and unique. An NFT can, for example, represent a unique piece of art or a game token.",
  },
  {
    id: 'mint',
    q: 'What does “MINT” mean?',
    a: `NFT Minting is the process by which your digital art or digital content becomes a part of the Ethereum blockchain, so it can be traded in the market and tracked digitally.`,
  },
  {
    id: 'blockchain',
    q: 'What blockchain is the project hosted on?',
    a: 'The Ethereum Blockchain.',
  },
  {
    id: 'howtobuy',
    q: 'How can I buy CryptoBear?',
    a: 'You need Ethereum and Metamask wallet to buy your CyyptoBear. The initial sell is available from our website. Then secondary sell would be possible by Opensea.',
  },
];
