'use client';

import { clx_ } from '@@libs/clx';
import { HTMLMotionProps, motion } from 'framer-motion';
import React, { ReactElement } from 'react';
import css from './Section.module.scss';

/**
 * Types
 */
type SectionProps = Omit<HTMLMotionProps<'div'>, 'title'> & {
  as?: any;
  id: string;
  title?: ReactElement | string;
  props?: {
    section?: HTMLMotionProps<'section'>;
    title?: HTMLMotionProps<'h1'>;
  };
};
export type BasicSectionProps = {
  title?: ReactElement | string;
  href: string;
};

/**
 * Contants
 */
const clx = clx_(css);

/**
 * Component: Section
 */
export default function Section({ as: Component = motion.section, id, title, props = {}, className, children, ...containerProps }: SectionProps) {
  const { className: sectionClassName, ...sectionProps } = props.section ?? {};
  const { className: titleClassName, ...titleProps } = props.title ?? {};

  return (
    <Component id={id} className={clx('flex w-full max-w-screen-lg flex-col gap-4', sectionClassName)} {...sectionProps}>
      {title && (
        <motion.h1
          className={clx('title', titleClassName)}
          whileInView="show"
          initial="hide"
          viewport={{ margin: '2000px 0px -200px 0px' }}
          variants={{
            hide: {
              opacity: 0,
              scale: 0.6,
            },
            show: {
              opacity: 1,
              scale: 1,
            },
          }}
          {...titleProps}
        >
          {title}
        </motion.h1>
      )}
      <motion.div className={clx('container', className)} {...containerProps}>
        {children}
      </motion.div>
    </Component>
  );
}
