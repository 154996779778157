import Community from '@@components/sections/community/Community';
import Faq from '@@components/sections/faq/Faq';
import Roadmap from '@@components/sections/roadmap/Roadmap';

export const SocialLinks = [
  {
    name: 'Telegram',
    icon: '/imgs/social/telegram.svg',
    href: 'https://t.me/pipandchart',
  },
  {
    name: 'TikTok',
    icon: '/imgs/social/tiktok.svg',
    href: '#',
  },
  {
    name: 'Facebook',
    icon: '/imgs/social/facebook.svg',
    href: '#',
  },
  {
    name: 'Instagram',
    icon: '/imgs/social/instagram.svg',
    href: '#',
  },
];
export const PageContent = [
  // { href: 'home', component: Header },
  // { title: 'Mint', href: 'mint', component: Mint },
  // { title: 'Legendaries', href: 'legendaries', component: Legendaries },
  { title: 'Roadmap', href: 'roadmap', component: Roadmap },
  // { title: 'Marketplace', href: '#marketplace', component: Marketplace },
  // { title: 'Team', href: 'team', component: Team },
  { title: 'Community', href: 'community', component: Community },
  { title: 'FAQ', href: 'FAQ', component: Faq },
];
